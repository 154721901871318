@import '../variables/colors';

// navigation containers START
.navbar {
  border: 0;
  border-radius: 0;
  margin: 0 -15px;
}
.navbar .row {
  margin: 0;
}
.navbar .row > * {
  padding: 0;
}
.nav-primary {
  display: flex;
  justify-content: flex-end;
}
.nav-primary nav {
  flex: 0 0 75%;
  margin-right: auto;
}
.nav-primary .navbar-nav {
  margin-top: 0;
}
.nav-primary .company-name {
  margin-left: 25px;
  margin-right: 25px;
}
.nav-primary .search {
  flex: 0 0 25%;
  float: none;
  padding-right: 1px;
  width: auto;
}
.nav-primary .search-form {
  display: flex;
  float: none;
}
.nav-primary .search-form .form-control {
  flex: 1 1 auto;
  width: inherit !important;
}
// Nav container ENDs


/*
Add icons next to Brands
Use the brand colours for the under line of each brand
Make titles upper and lower case
*/


/* ––––––––––––––––––––––––––––––––––––––––––––––––––
megamenu.js STYLE STARTS HERE
–––––––––––––––––––––––––––––––––––––––––––––––––– */


/* ––––––––––––––––––––––––––––––––––––––––––––––––––
Screen style's
–––––––––––––––––––––––––––––––––––––––––––––––––– */

.menu-mobile {
  display: none;
  padding: 20px;
  &:after {
    content: "\f394";
    font-family: "Ionicons";
    font-size: 2.5rem;
    padding: 0;
    float: right;
    position: relative;
    top: 50%;
    transform: translateY(-25%);
  }
}

// Selecting nav menu anchors
.menu > ul > li > a {
  text-align: center;
}

.menu-dropdown-icon {
  &:before {
    content: "\f489";
    font-family: "Ionicons";
    display: none;
    cursor: pointer;
    float: right;
    padding: 1.5em 2em;
    background: #fff;
    color: #333;
  }
}

.menu-dropdown-icon > ul {
  // TODO: Refactor important: NO important
}


.menu-dropdown-icon > ul > li {
  padding-left: 7px;
  padding-right: 7px;
}

.menu-dropdown-icon > ul > li:not(:last-child) {
  border-right: 1px solid white;
}

.menu-dropdown-icon > ul {
}

.megamenu-sub-wrapper {
}

.megamenu-filter-icon {
  cursor: pointer;
  font-size: 12px;
}

.megamenu-filter-text-wrapper {
  display: none;
}

.megamenu-subtitle > img {
  width: 30px;
  margin-right: 10px;
}

.megamenu-filter {
  line-height: 18px;
  padding: 5px;
}

.menu {
  > ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    list-style: none;
    padding: 0;
    position: relative;
    //position: relative;
    /* IF .menu position=relative -> ul = container width, ELSE ul = 100% width */
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    &:before,
    &:after {
      content: "";
      display: table;
    }
    &:after {
      clear: both;
    }
    > li:first-child {
    }
    > li {
      margin: 0 5px !important;
      a {
        display: block;
        padding: 0 5px;
        text-decoration: none;
        text-transform: capitalize;
      }
      &:hover {
        background: #f0f0f0;
      }
      > ul {
        display: none;
        width: 100%;
        background: #f0f0f0;
        padding: 20px;
        position: absolute;
        z-index: 99;
        left: 0;
        margin: 0;
        list-style: none;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        &:before,
        &:after {
          content: "";
          display: table;
        }
        &:after {
          clear: both;
        }
        > li {
          margin: 0;
          padding-bottom: 0;
          list-style: none;
          width: 25%;
          background: none;
          float: left;
          a {
            color: #777;
            padding: .2em 0;
            width: 95%;
            display: block;
            border-bottom: 1px solid #ccc;
          }
          > ul {
            display: block;
            padding: 0;
            margin: 10px 0 0;
            list-style: none;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            &:before,
            &:after {
              content: "";
              display: table;
            }
            &:after {
              clear: both;
            }
            > li {
              float: left;
              width: 100%;
              margin: 0;
              font-size: .8em;
              line-height: 10px;
              a {
                line-height: 15px;
                border: 0;
                padding-top: 7px;
                padding-bottom: 7px;

                &:hover {
                  background: #ddd;
                }
              }
            }
          }
        }
        &.normal-sub {
          width: 300px;
          left: auto;
          padding: 10px 20px;
          > li {
            width: 100%;
            a {
              border: 0;
            }
          }
        }
      }
    }
  }
}

// responsiveness
@media all and (max-width: 768px) {
  .nav-primary {

    nav {
      flex: 0;
      margin-right: 0;
      align-self: center;
    }
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    .company-name {
    }
    .menu {
      justify-self: center;
    }
    .menu ul {
      display: flex;
      flex-direction: column;
    }
    .megamenu-sub-wrapper {
      display: none !important;
    }
  }
}
