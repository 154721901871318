

.swiper-container {
  flex: 0 0 75%;
  overflow: hidden;
}

#swiper-tabs {
  flex: 0 0 25%;
  display: flex;
  flex-direction: column;
  .swiper-tab {
    align-items: center;
    background: #0053a6;
    border-top: 3px solid #fff;
    color: #fff !important;
    display: flex;
    flex: 1 1 auto;
    font-size: 25px;
    font-weight: 600;
    padding-left: 35px;
    box-sizing: border-box;
    width: 100%;
    span {
      color: #fff;
    }

    &:first-child {
      border-top: none;
    }

    &:hover {
      background-color: #000a25;
      text-decoration: none;
    }
  }
}

@media screen and (max-width: 992px) {

  #swiper-tabs .swiper-tab {
    font-size: 20px;
  }
}

@media screen and (max-width: 768px) {
  header.navbar {
    display: none;
  }

  #swiper-tabs .swiper-tab {
    font-size: 17px;
  }
}