@import '../variables/colors';

.side-nav {
  border-bottom: @border-default;
  margin: 44px 0 0 -15px;
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  li a {
    border-top: @border-default;
    display: block;
    font-size: 16px;
    font-weight: 600;
    padding: 15px 15px 15px 100px;
  }
  li a:hover {
    background: #fafafa;
  }
}

@media screen and (max-width: 768px) {
  .side-nav {
    li a {
      padding: 15px 15px 15px 50px;
    }
  }
}