@import '../variables/colors';

.brand {
  margin-bottom: 25px;
}
.brand h2 {
  color: #fff;
  padding: 30px 20px;
  background: @dark-blue;
}
.brand h2 a {
  color: #fff;
}
.product-heading-subscript {
  font-size: 11px;
  margin-left: 15px;
}
.product-heading-subscript i {
  font-size: 8px;
  position: relative;
  top: -1px;
}

// Product category
.product-category-list ul {
  list-style-type: none;
  padding: 0;
}
.product-category-list li {
  margin: 5px 0;
}
.product-category-list a {
  font-weight: 600;
}

// Product list
.product-list {
  list-style-type: none;
  padding: 0;
  width: 100%;
}
.product-list tr {
  border-bottom: @border-default;
}
.product-list td:first-child {
  padding: 5px 15px 5px 0;
}

.product-brand-logo {
  height: 50px;
}

.product-brand h2 {
  background: @dark-blue;
  padding: 0;
  margin-bottom: 0;

  a {
    display: block;
    width: 100%;
    padding: 30px 20px;
    line-height: 50px;
    text-decoration: none;
    
    img {
      float: right;
    }
  }
}

.product-brand .description {
  background: #f5f5f5;
  padding: 15px;
}

#product-list {
  div.search {
    border: 1px solid #bbb;
    border-radius: 14px;
    padding: 2px 10px;
    margin-bottom: 10px;

    input {
      border: none;
      outline: none;
      width: 90%;
    }
  }

  ul {
    padding: 0;
    margin: 0;
  }
  li {
    list-style: none;
    padding: 5px 0;
    border-bottom: @border-default;
  }
}