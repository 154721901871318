.navbar {
  margin-bottom: 0;
}
.cmi-banner {
  background: #000;
}

.search-limited div {
  padding-right: 10px;
}
.search-form {
  background: @grey;
  float: right;
  width: 100%;
  
  input[type="text"] {
    display: inline-block;
    border: none;
    background: none;
    box-shadow: none;
    height: 36px;
  }

  button {
    background: none;
    border: none;
    line-height: 36px;
    padding: 0 10px;
  }
}

header {
  line-height: 36px;
  min-height: 43px !important;
}

@media screen and (min-width: 768px) and (max-width: 992px) {
  .company-name {
    display: none;
  }
  header.navbar .search {
    flex: 0 0 15%;
  }
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
  header.navbar .search {
    flex: 0 0 23%;
  }
}