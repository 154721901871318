//* !Body */
//* ----------------------------------------------- */

body {
  padding: 0;
  margin: 0;
  min-width: 320px;

  font-family: @font-stack-text;
  font-size: 13px;
  overflow-y: scroll;
}



//* !Text */
//* ----------------------------------------------- */

p {
  line-height: 1.7em;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-top: 0;
  margin-bottom: .75em;
  font-family: @font-stack-heading;
}

label {
  font-weight: 600;
}

.semibold {
  font-weight: 600;
}

// Images
img {
  height: auto;
  max-height: 100%;
  max-width: 100%;
  width: auto;
}
