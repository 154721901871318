.certification-category {
  display: table;
  margin-bottom: 20px;
}
.certification-category a {
  border: 10px solid #eee;
  display: table-cell;
  width: 100%;
  height: 100px;
  vertical-align: middle;
  padding: 10px;

  &:hover {
    text-decoration: none;
    background: #eee;
    color: #000;
  }
}