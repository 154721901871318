.product-brand {
  > h2 {
    display: flex;
    align-items: center;
    img {
      margin-left: auto;
      width: 50px;
      height: 50px;
    }
  }
}

#brand-menu {
  .sub-menu {
    margin-left: 20px;
    display: none;
  }
}

.template-aflex .aflex-heading {
  margin-left: 0;
}

.product-banner {
  color: #fff !important;

  div {
    color: #fff !important;
  }
}

.product-view-options {
  i.fa {
    font-size: 32px;
    vertical-align: sub;
  }
}