#cable-size-calculator {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 10px !important;
  width: 100%;

  @media only screen and (max-width: 991px) {
    margin-bottom: 20px;
  }

  @media print {
      display: flex !important;
  }

  select, input {
    margin-bottom: 10px;
    @media print {
      border: 1px solid #ccc;
    }

    &:disabled {
      color: #ccc;
    }
  }

  .form-item {
    display: flex;
    flex-direction: column;
    flex-basis: 50%;
    padding: 10px 10px 0;

    @media only screen and (max-width: 720px) {
      flex-basis: 100%;
    }
  }

  .form-item-20 {
    flex-basis: 20% !important;
  }
  .form-item-25 {
    flex-basis: 25% !important;
  }
  .form-item-30 {
    flex-basis: 30% !important;
  }
  .form-item-50 {
    flex-basis: 50% !important;
  }

  hr {
    flex-basis: 100%;
  }
}

#calculator-left, #calculator-right {
  @media print {
    float: left !important;
    width: 50% !important;
  }
}

#cable-matrix {
  thead th {
    background: #000a25;
    color: #fff;
  }
  tr.success {
    font-weight: bold;
  }
}

#result-details-table {
  th {
    background: #000a25;
    color: #fff;
  }
  tbody {
    td:first-child {
      text-align: left;
      text-align-last: left;
      font-weight: bold;
    }
  }
}

table#suggestion {
  margin-top: 20px;

  th {
    background: #000a25;
    color: #fff;
  }

  table {
    td {
      border: none;
      width: 50%;

      img {
        max-height: 150px;
      }
    }
  }

  a {
    word-break: break-all;
  }
}

.calculator-instructions {
  border: 1px solid #ccc;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 10px !important;
  width: 100%;

  div {
    display: flex;
    flex-direction: column;
    flex-basis: 88%;

    &:first-child {
      align-self: center;
      font-size: 40px;
      color: #000a25;
      flex-basis: 12%;
      text-align: center;
    }
  }
}