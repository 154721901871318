@import '../variables/colors';

.content {
  background: @background-color;
  overflow: hidden;
}
.main-content {
  padding: 20px 96px 40px;
}
.inner-content {
  padding: 20px 96px 40px 0;
}

@media screen and (max-width: 768px) {
  .main-content {
    padding: 20px 0 40px;
  }
  .inner-content {
    padding: 20px 0 40px 0;
  }
}