// TODO: Refactor this using extend
.page-view > div:first-child{
  border-bottom: @border-default;
  margin: 44px 0 0 -15px;
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  li a {
    border-top: @border-default;
    display: block;
    font-size: 16px;
    font-weight: 600;
    padding: 15px 15px 15px 100px;
  }
  li a:hover {
    background: #fafafa;
  }
}

.page-view > div:nth-child(2) {
  margin: 44px 0 !important;
}

.page-view {
  padding-right: 96px;
}

@media screen and (max-width: 768px) {
  .page-view {
    padding-right: 0;
  }
}

.page-footer-logos {
  background-color: @lighter-blue;
}