@import '../variables/colors';

.form {
  background: @background-color;
  border: 10px solid @border-color;
  padding: 40px;
}
.form-header {
  margin-bottom: 25px;
}
.form-title {
  display: block;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
}
.form label {
  font-weight: 400;
}

// Inputs
.form-control {
  background-color: @form-input-background-color;
  border: none;
  border-radius: 0;
  box-shadow: none;
}
.form-control::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: @form-input-placeholder-color;
}
.form-control::-moz-placeholder { /* Firefox 19+ */
  color: @form-input-placeholder-color;
}
.form-control:-ms-input-placeholder { /* IE 10+ */
  color: @form-input-placeholder-color;
}
.form-control:-moz-placeholder { /* Firefox 18- */
  color: @form-input-placeholder-color;
}

// Radio buttons
.form .radio-list label {
  margin-right: 20px;
}
.form .radio-list label:hover {
  cursor: pointer;
}
.form .radio-list [type=radio] {
  display: none;
}
.form [type=radio] + span {
  align-items: center;
  display: flex;
}
.form [type=radio] + span:after {
  background: @form-input-background-color;
  content: '';
  display: inline-block;
  height: 24px;
  margin-left: 10px;
  padding: 2px 0 0 5px;
  width: 24px;
}
.form [type=radio]:checked + span:after {
  content: '\f00c';
  font-family: FontAwesome;
  font-size: 14px;
}

// Buttons
.form [type=submit] {
  background: @primary-color;
  border: 0;
  border-radius: 0;
  font-weight: 600;
}
.form [type=submit]:active,
.form [type=submit]:focus {
  background: lighten(@primary-color, 5%);
}

form.notification-form {
  display: inline-block;

  input, button {
    display: inline-block;
  }

  button {
    padding: 7px 10px;
    margin-top: -2px;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
  }
}