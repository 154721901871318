@import '../variables/colors';

.product-content {
  margin-top: 25px;
  clear: both;
}
.product-heading {
  border-radius: 0;
  color: #fff;
  margin-bottom: 30px;
  padding: 25px 20px;
}
.product-subheading {
  margin-top: 5px;
}

.attributes-column {
  background-color: @attributes-table-background-color;
  margin: 15px 0;
  padding: 10px 15px;
}
.attributes-column .row {
  border-bottom: @border-width @border-style @attributes-table-border-color;
  margin: 0;
  padding: 5px 0;
}
.attributes-column .row > div {
  padding: 0;
}
.attributes-column .row:last-child {
  border: 0;
}

.logo-container {
  /*display: none;*/
}
.product-image-container {
  border: @border-default;

  img {
    width: 100% !important;
  }
}

.product-content {
  .table-container table {
    border: 1px solid #ccc;
  }

  .table-wrapper {
    margin-bottom: 20px;
  }
}

.fa-print {
  font-size: 32px;
  vertical-align: sub;
}

@media screen and (max-width: 768px) {
  .product-content {
    padding: 0 10px;
  }
}

.template-xlpe h1, .template-xlpe h2 {
  padding: 10px;
}

.product-subheading {
  p, span, h1, h2, h3, h4 {
    color: #fff !important;
  }
}