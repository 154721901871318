.notification-category {
  display: table;
  margin-bottom: 20px;
}
.notification-category a {
  border: 10px solid #eee;
  display: table-cell;
  width: 100%;
  height: 100px;
  vertical-align: middle;
  padding: 10px;

  &:hover {
    text-decoration: none;
    background: #eee;
    color: #000;
  }
}

.pagination {
  li {

    a {
      color: @dark-blue;
    }
    &.active {
      a {
        background-color: @dark-blue;
        border-color: @dark-blue;
        color: #white;

        &:hover {
          background-color: @light-blue;
          border-color: @light-blue;
          color: @dark-blue;
        }
      }
    }
  }
}