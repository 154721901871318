@import '../variables/colors';

.table-container {
  max-width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}

table.table thead th {
  text-align: center;
}
table.table thead th.table-column.sku {
  text-align: left;
}
table.table tbody tr td {
  text-align: justify;
  text-align-last: center;
}

table.table table[data-type="spare-parts"] {
  thead th, tbody tr td {
    text-align: left !important;
    text-align-last: left !important;
  }
}

.certification-table.table thead th:first-child,
.notification-table.table thead th:first-child {
  text-align: left;
}
.certification-table.table tbody tr td:first-child,
.notification-table.table tbody tr td:first-child {
  text-align: left !important;
  text-align-last: left !important;
}

@media screen and (max-width: 768px) {
  .table-container {
    border-left: @border-default;
    box-shadow: inset -1px 0 5px 2px rgba(0, 0, 0, 0.1)
  }
}
