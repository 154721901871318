@import '../variables/colors';
@import '../variables/variables';

.footer {
  background: none;
  font-size: 11px;
}
.footer .container {
  margin-bottom: 15px;
}
.footer hr {
  margin: 10px -15px 20px;
  border-top: 1px solid #2e2e2e;
}
.footer-addresses {
  margin-top: 20px;
  font-size: 11px;
}
.footer-addresses > div {
  padding-bottom: 10px;
}
.footer-menu {
  text-transform: uppercase;
}
.footer-menu,
.footer-links {
  padding: 0;
}
.footer-links {
  float: right;
}
.footer-menu li {
  font-weight: 600;
  padding-right: 25px;
}
.footer-links li {
  border-right: @border-width @border-style #1a1818;
  margin-right: 6px;
  padding-right: 9px;
}
.footer-menu li,
.footer-links li {
  display: inline-block;
  list-style-type: none;

  &:last-child {
    border: 0;
    padding: 0;
  }
}

@media screen and (max-width: 1199px) {
  .footer-links {
    float: none;
  }
}
@media screen and (max-width: 425px) {
  .footer .col-xs-6 {
    width: 100%;
  }
}
