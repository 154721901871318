.brands {
  align-items: center;
  background: @lighter-blue;
  display: flex;
  height: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
}
.brands > li {
  flex: 1 1 0;
  padding: 25px 25px;
}
@media screen and (max-width: 768px) {
  .brands {
    flex-direction: column;
  }
}
