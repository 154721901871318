.modal-header {
  background: @dark-blue;
  color: #fff;

  .modal-title {
    color: #fff;
    float: left;
  }

  button.close {
    opacity: 1 !important;
    text-shadow: none !important;

    span {
      color: #fff !important;
      opacity: 1 !important;
      text-shadow: none !important;
    }
  }
}