@import '../variables/variables';

.page-home {
  margin: 0 -15px;
}
.page-home .row {
  margin: 0;
}
.page-home .row > * {
  padding: 0;
}
.page-home .row-blocks {
  display: flex;
}

.home-slider {
  display: flex;
}
.home-slider .bx-wrapper {
  flex: 0 0 75%;
  overflow: hidden;
}
.home-slider .bx-pager {
  flex: 0 0 25%;
}
.home-slider .bx-pager {
  display: flex;
  flex-direction: column;
}
.home-slider .bx-pager a {
  align-items: center;
  background: @blue;
  border-top: 3px solid #fff;
  color: #fff;
  display: flex;
  flex: 1 1 auto;
  font-size: 25px;
  font-weight: 600;
  padding-left: 35px;

  &:hover, &:active, &:focus {
    background: @dark-blue;
    cursor: pointer;
    text-decoration: none !important;
  }
  &.active {
    background: @dark-blue;
  }
}
.home-slider .bx-pager a:first-child {
  border: 0;
}
.home-slider .bx-pager span {
  color: #fff;
}

.tagline {
  background: @light-blue;
  color: #fff;
  padding: 20px 25px;
}
.tagline-title,
.text-block-title {
  font-size: 32px;
  font-weight: 600;
  line-height: 130%;
  margin-bottom: 10px;
}
.tagline .tagline-intro {
  font-size: 15px;
}
.tagline p {
  color: #fff;
  font-size: 12px;
}
.tagline a {
  color: #fff;
}
.tagline .link--view-more:before {
  background: #fff;
  color: #000;
}

.text-block {
  padding: 20px 25px;
}

.block-brand-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.block-brand-container > .row:last-child {
  display: flex;
  flex: 1 1 auto;
}
.block-brand-container .brands {
  list-style: none;
}

.link--view-more {
  align-items: center;
  display: flex;
  font-size: 12px;
}
.link--view-more:hover {
  cursor: pointer;
}
.link--view-more:before {
  align-items: center;
  background: #000;
  color: #fff;
  content: '\f054';
  display: flex;
  font-family: FontAwesome, sans-serif;
  font-size: 10px;
  height: 24px;
  justify-content: center;
  margin-right: 12px;
  width: 24px;
}

@media screen and (max-width: 991px) {
  .page-home .row-blocks {
    display: block;
  }
  .home-slider .bx-pager a {
    font-size: 18px;
    padding-left: 25px;
  }
}
@media screen and (max-width: 767px) {
  .home-slider .bx-wrapper {
    flex: 0 0 100%;
  }
  .home-slider .bx-pager {
    display: none;
  }
}
