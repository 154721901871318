@import '../variables/colors';

.project-page .bx-wrapper {
  border: 10px @border-style @border-color;
  margin-bottom: 10px;
}
.project-page dt {
  margin-bottom: 2px;
}
.project-page dd {
  border-bottom: @border-default;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 5px;
  padding-bottom: 5px;
}
.project-page dd:last-of-type {
  border: 0;
}
.project-description {
  margin-top: 20px;
}

.project-page .bx-wrapper .bx-controls-direction a {
  align-items: center;
  color: @border-color;
  font-size: 28px;
  font-weight: 600;
  height: 30px;
  line-height: 30px;
  margin-top: -20px;
  position: absolute;
  top: 50%;
  z-index: 1000;
}
.project-page .bx-wrapper .bx-controls-direction a:hover,
.project-page .bx-wrapper .bx-controls-direction a:focus {
  text-decoration: none;
}
.project-page .bx-wrapper .bx-next {
  right: 32px;
}
.project-page .bx-wrapper .bx-prev {
  left: 32px;
}


.project-page .swiper-container {
  border: 10px @border-style @border-color;
  margin-bottom: 10px;
}