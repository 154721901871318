.brands.tagline {
  li {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    img {
      width: 45px;
      height: 45px;
      margin-right: 10px;
    }
    a {
      color: #000;
    }
  }
}

.row-blocks {
  background: @light-blue;
  .block-brand-container {
    background-color: #fff;

    img {
      width: 100%;
    }
  }
}

.home-slider {
  ul.bxslider li img {
    width: 100%;
  }
}