a, p, span, ul, li, h1, h2, h3, h4, h5, h6, div {
  color: @dark-grey;
  font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

@font-face {
  font-family: 'Aller';
  src: url('../fonts/Aller.eot');
  src: url('../fonts/Aller.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Aller.woff') format('woff'),
  url('../fonts/Aller.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Aller';
  src: url('../fonts/Aller-Bold.eot');
  src: url('../fonts/Aller-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Aller-Bold.woff') format('woff'),
  url('../fonts/Aller-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}