h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
}
h1 {
  font-size: 24px;
}
h2 {
  font-size: 20px;
}
h3 {
  font-size: 16px;
}
h4 {
  font-size: 14px;
}

.no-margin-top {
  margin-top: 0;
}