body {
  background: @grey;
}

header {
  background: #fff;
}

.bg-white {
  background: #fff;
}

.col-100, .col-75, .col-50, .col-33, .col-25, .col-20 {
  display: inline-block;
  float: left;
}
.col-100 {
  width: 100%;
}
.col-75 {
  width: 75%;
}
.col-50 {
  width: 50%;
}
.col-33 {
  width: 33.333333%;
}
.col-25 {
  width: 25%;
}
.col-20 {
  width: 20%;
}

#menu {
  display: none;
}

@media screen and (max-width: 768px) {
  header.navbar {
    display: none;
  }
}

@media screen and (max-width: 40em) {
  header.navbar {
    display: none;
  }

  #swiper-tabs {
    display: none;
  }

  .swiper-container {
    flex: 0 0 100% !important;
  }
}

.slicknav_menu {
  background-color: #1d1d1d !important;
  padding: 0 !important;
}

.slicknav_btn {
  padding: 3px 5px !important;
  float: left !important;
}
.tooltip-inner {
  max-width: 275px !important;
}